// src/components/Hero.js

import React, { useState } from "react";
import HowItWorks from "../pages/HowItWorks";

const Hero = () => {
  const [url, setUrl] = useState("");
  const [videoInfo, setVideoInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // State for error handling

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null); // Reset error state
    try {
      const apiResponse = await mockApiCall(url);
      setVideoInfo(apiResponse);
    } catch (err) {
      setError("Failed to fetch video information"); // Error handling
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-6 py-10 md:py-20 max-w-4xl">
      <div className="text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
          Find beats per minute (BPM) and key of any YouTube video
        </h2>
        <UrlInputForm
          url={url}
          isLoading={isLoading}
          onInputChange={handleInputChange}
          onSubmit={handleSubmit}
        />
      </div>
      {error && <p className="text-red-500">{error}</p>}{" "}
      {/* Display error message */}
      {videoInfo && <VideoInfo videoInfo={videoInfo} />}
      <div className="mt-10"></div>
      <HowItWorks />
    </div>
  );
};

const UrlInputForm = ({ url, isLoading, onInputChange, onSubmit }) => {
  return (
    <div className="flex justify-center items-center">
      <input
        type="text"
        className="p-2 rounded-l-lg rounded-r-none flex-1"
        placeholder="Enter YouTube URL"
        value={url}
        onChange={onInputChange}
      />
      {isLoading ? <LoadingButton /> : <SubmitButton onClick={onSubmit} />}
    </div>
  );
};

// Loading button
const LoadingButton = () => (
  <button
    disabled
    type="button"
    className="text-white bg-gradient-to-r from-indigo-800 to-purple-900  focus:ring-4 focus:ring-blue-300 font-medium rounded-r-lg text-sm px-5 py-2.5 text-center mr-2 dark:focus:ring-indigo-800 inline-flex items-center min-w-[120px]"
  >
    <svg
      aria-hidden="true"
      role="status"
      className="inline w-4 h-4 mr-3 text-white animate-spin"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="#E5E7EB"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentColor"
      />
    </svg>
    Loading...
  </button>
);

// Submit button
const SubmitButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="bg-gradient-to-r  from-indigo-800 to-purple-900 text-white py-2 px-4 rounded-r-lg min-w-[120px]"
  >
    Submit
  </button>
);

// Function to render video info, like BPM, key
const VideoInfo = ({ videoInfo }) => (
  <div className="flex flex-col justify-center items-center mt-8 md:mt-20 shadow-lg rounded-lg overflow-hidden">
    <YouTubeVideoFrame url={videoInfo.url} />
    <KpiGrid videoInfo={videoInfo} />
  </div>
);

// Function to render youtobe video frame
const YouTubeVideoFrame = ({ url }) => {
  const videoId = extractYouTubeId(url);
  if (!videoId) return <p className="text-red-500">Invalid YouTube URL</p>;

  return (
    <iframe
      width="100%"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      allowFullScreen
    ></iframe>
  );
};

const KpiGrid = ({ videoInfo }) => (
  <div className="flex justify-around text-white bg-gradient-to-r from-indigo-800 to-purple-900 w-full">
    <div className="p-4">
      <h3 className="text-md mb-2">Key</h3>
      <p className="text-md sm:text-lg lg:text-xl font-bold">{videoInfo.key}</p>
    </div>
    <div className="p-4">
      <h3 className="text-md mb-2">BPM</h3>
      <p className="text-md sm:text-lg lg:text-xl font-bold">{videoInfo.bpm}</p>
    </div>
    <div className="p-4">
      <h3 className="text-md mb-2">Duration</h3>
      <p className="text-md sm:text-lg lg:text-xl font-bold">
        {videoInfo.duration}
      </p>
    </div>
  </div>
);

// Mock API call function
const mockApiCall = (url) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ url, key: "C Major", bpm: "120", duration: "3:45" }); // Mock response
    }, 2000);
  });
};

// Function to extract YouTube video ID from URL
const extractYouTubeId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return ""; // handle error
  }
};

export default Hero;
