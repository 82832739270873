import React from "react";

const HowItWorks = () => {
  return (
    <div
      className="container mx-auto px-6 py-6 text-white max-w-4xl bg-gradient-to-r from-indigo-800 to-purple-900 rounded-lg"
      // style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}
    >
      <h1 className="text-xl md:text-xl sm:text-xl lg:text-2xl font-bold mb-4">
        How BPM Calculation Works
      </h1>
      <p className="text-md md:text-lg mb-3">
        BPM, or Beats Per Minute, is a unit for measuring the tempo of music. It
        quantifies the number of beats that occur in one minute, indicating the
        pace of a song. Here's how our BPM calculation process works:
      </p>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-4">
          <strong>Audio Analysis:</strong> The process begins with the analysis
          of the audio track. Our system processes the uploaded audio file to
          detect rhythmic elements.
        </li>
        <li className="mb-4">
          <strong>Beat Detection:</strong> By identifying peaks in the audio
          signal, the algorithm pinpoints the beats in the track.
        </li>
        <li className="mb-4">
          <strong>Tempo Estimation:</strong> The intervals between successive
          beats are calculated to estimate the track's tempo in beats per
          minute.
        </li>
        <li>
          <strong>Output:</strong> The final BPM value is then presented,
          providing an accurate measure of the song's tempo.
        </li>
      </ol>
      <p className="text-lg">
        Understanding the BPM of a song is crucial for various applications,
        including music production, DJing, and fitness workouts. Our tool offers
        a quick and accurate way to determine this important aspect of a track.
      </p>
    </div>
  );
};

export default HowItWorks;
