import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import backgroundImage from "../assets/bg.jpg";

const Layout = () => {
  return (
    <div>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 min-h-screen">
        <Header />
        <div className="pt-[56px] min-h-screen">
          <Outlet /> {/* This will render the current route's component */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
