import React from "react";
import { Link } from "react-router-dom"; // Import Link

const Header = () => {
  return (
    <nav className="bg-black/75 bg-blend-darken shadow-lg fixed top-0 w-full z-20">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        <Link to="/" className="font-bold text-xl text-white">
          VoiceVeda
        </Link>
        <div>
          <Link to="/" className="text-slate-300 hover:text-slate-50 px-3">
            Home
          </Link>
          <Link
            to="/how-it-works"
            className="text-slate-300 hover:text-slate-50 px-3"
          >
            How it works?
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
